import { useRef, type FC } from 'react'
import styles from './styles/image-thumbnail.module.scss'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

const THUMBNAIL_FETCH_MAX_RETRY = 10

export interface ImageThumbnailProps {
  imageThumbnailUrl?: string
  imagePredictionMaskThumbnailUrl?: string
  width: number
  height: number
}

export const ImageThumbnail: FC<ImageThumbnailProps> = (props) => {
  const intl = useIntl()
  const imageThumbnailErrorCountRef = useRef(0)
  const imagePredictionMaskThumbnailErrorCountRef = useRef(0)

  const noImageText = intl.formatMessage({
    id: 'image-thumbnail.no-image.text',
    defaultMessage: 'No image',
  })

  const { imageThumbnailUrl, imagePredictionMaskThumbnailUrl, width, height } = props
  return <div className={styles.thumbnailContainer} style={{ width, height }}>
    {
      imageThumbnailUrl !== undefined
        ? <>
            <img
              className={clsx(styles.thumbnail, styles.thumbnailImage)}
              src={imageThumbnailUrl}
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                imageThumbnailErrorCountRef.current += 1
                if (imageThumbnailErrorCountRef.current > THUMBNAIL_FETCH_MAX_RETRY) {
                  return
                }
                if (e.target !== undefined) {
                  setTimeout(() => {
                    (e.target as HTMLImageElement).src = imageThumbnailUrl
                  }, 1000 * imageThumbnailErrorCountRef.current)
                }
              }}
              width={width}
            />
            {
              imagePredictionMaskThumbnailUrl !== undefined &&
                <img
                  className={clsx(styles.thumbnail, styles.thumbnailPrediction)}
                  src={imagePredictionMaskThumbnailUrl}
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    imagePredictionMaskThumbnailErrorCountRef.current += 1
                    if (imagePredictionMaskThumbnailErrorCountRef.current > THUMBNAIL_FETCH_MAX_RETRY) {
                      return
                    }
                    if (e.target !== undefined) {
                      setTimeout(() => {
                        (e.target as HTMLImageElement).src = imagePredictionMaskThumbnailUrl ?? ''
                      }, 1000 * imagePredictionMaskThumbnailErrorCountRef.current)
                    }
                  }}
                  width={width}
                />
            }
          </>
        : <div className={styles.missingThumbnailImage}>{noImageText}</div>
    }
  </div>
}

