import type BaseLayer from 'ol/layer/Base'
import WebGLTileLayer from 'ol/layer/WebGLTile'
import { type Projection } from 'ol/proj'
import { type TileCoord } from 'ol/tilecoord'
import { XYZ as XYZSource } from 'ol/source'
import { type MaskColors } from './color'
import TileGrid from 'ol/tilegrid/TileGrid'
import { type ImageTile, type Tile } from 'ol'
import type ImageTileSource from 'ol/source/ImageTile'

const RETRY_CODES = [408, 425, 429, 500, 502, 503, 504]
// Default retry configuration
const DEFAULT_MAX_RETRIES = 3
const DEFAULT_DELAY_FACTOR_BETWEEN_RETRY_IN_MS = 1000
const DEFAULT_BASE_DELAY_BETWEEN_RETRY_IN_MS = 0
// 425 TOO EARLY retry configuration
const MAX_RETRIES_425 = 100
const DELAY_FACTOR_BETWEEN_RETRY_IN_MS_425 = 0
const BASE_DELAY_BETWEEN_RETRY_IN_MS_425 = 5000

const getMaxRetry = (statusCode: number): number => {
  switch (statusCode) {
    case 425:
      return MAX_RETRIES_425
    default:
      return DEFAULT_MAX_RETRIES
  }
}
const getDelayFactor = (statusCode: number): number => {
  switch (statusCode) {
    case 425:
      return DELAY_FACTOR_BETWEEN_RETRY_IN_MS_425
    default:
      return DEFAULT_DELAY_FACTOR_BETWEEN_RETRY_IN_MS
  }
}
const getDelayBase = (statusCode: number): number => {
  switch (statusCode) {
    case 425:
      return BASE_DELAY_BETWEEN_RETRY_IN_MS_425
    default:
      return DEFAULT_BASE_DELAY_BETWEEN_RETRY_IN_MS
  }
}

interface ImageMetadata {
  mosaicId: string
  x: number
  y: number
  width: number
  height: number
  tileSize?: number
  rotation?: number
}
export enum ClemexMosaicSourceType {
  IMAGE = 'IMAGE',
  IMAGE_SOURCE = 'IMAGE_SOURCE',
  MASK = 'MASK',
  MASK_SOURCE = 'MASK_SOURCE',
}

const roundToNextPower2 = (value: number): number => {
  return Math.pow(2, Math.ceil(Math.log2(value)))
}
export type ClemexMosaicSource = ClemexMosaicImageSource | ClemexMosaicImage | ClemexMosaicMaskSource | ClemexMosaicMask

abstract class ClemexMosaicImageBase {
  abstract type: ClemexMosaicSourceType
  public baseURL: string
  public mosaicId: string
  public x: number
  public y: number
  public width: number
  public height: number
  public rotation: number
  public tileSize: number

  constructor (imageMetadata: ImageMetadata & { baseURL: string }) {
    this.mosaicId = imageMetadata.mosaicId
    this.baseURL = imageMetadata.baseURL
    this.x = imageMetadata.x
    this.y = imageMetadata.y
    this.width = imageMetadata.width
    this.height = imageMetadata.height
    this.rotation = imageMetadata.rotation ?? 0
    this.tileSize = imageMetadata.tileSize ?? 512
  }

  abstract getUrl (z: number, x: number, y: number): string

  public get extent (): [number, number, number, number] {
    return [this.x, this.y - this.height, this.x + this.width, this.y]
  }

  public get mosaicExtent (): [number, number, number, number] {
    return [this.x, this.y - roundToNextPower2(this.height), this.x + roundToNextPower2(this.width), this.y]
  }

  public get maxZoom (): number {
    const largestSide = Math.max(this.width, this.height)
    return Math.ceil(Math.log2(largestSide / this.tileSize))
  }
}
export class ClemexMosaicImage extends ClemexMosaicImageBase {
  public type: ClemexMosaicSourceType.IMAGE = ClemexMosaicSourceType.IMAGE
  public getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/tile/${this.mosaicId}/image/${z}/${x}/${y}.png`
  }
}
export class ClemexMosaicImageSource extends ClemexMosaicImageBase {
  public type: ClemexMosaicSourceType.IMAGE_SOURCE = ClemexMosaicSourceType.IMAGE_SOURCE
  public imageSourceId: string
  constructor (imageMetadata: ImageMetadata & { baseURL: string, imageSourceId: string }) {
    super(imageMetadata)
    this.imageSourceId = imageMetadata.imageSourceId
  }

  public getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/tile/${this.mosaicId}/image_source/${this.imageSourceId}/${z}/${x}/${y}.png`
  }
}

export class ClemexMosaicMask extends ClemexMosaicImageBase {
  public type: ClemexMosaicSourceType.MASK = ClemexMosaicSourceType.MASK
  public maskIndex: number
  constructor (imageMetadata: ImageMetadata & { baseURL: string, maskIndex: number }) {
    super(imageMetadata)
    this.maskIndex = imageMetadata.maskIndex
  }

  public getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/tile/${this.mosaicId}/mask/${this.maskIndex}/${z}/${x}/${y}.png`
  }
}
export class ClemexMosaicMaskSource extends ClemexMosaicImageBase {
  public type: ClemexMosaicSourceType.MASK_SOURCE = ClemexMosaicSourceType.MASK_SOURCE
  public maskSourceId: string
  public maskIndex: number

  constructor (imageMetadata: ImageMetadata & { baseURL: string, maskSourceId: string, maskIndex: number }) {
    super(imageMetadata)
    this.maskSourceId = imageMetadata.maskSourceId
    this.maskIndex = imageMetadata.maskIndex
  }

  public getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/tile/${this.mosaicId}/mask_source/${this.maskSourceId}/${z}/${x}/${y}.png`
  }
}

export const makeLayerFromImageSource = (imageSource: ClemexMosaicSource, maskGroupLayerColor: MaskColors, projection: Projection): BaseLayer => {
  // retries is local to the function, so there is a retry counter for each imageSource
  const retries: Record<string, number> = {}

  // XXX: XYZSource is deprecated, but this implementation works better than the drop in replecement ImageTileSource
  //      As it is working, we can keep it for now
  //      If it stop working, then we need to make sure to fix the issues:
  //        - no artifact renderer in between tiles
  //        - make retry fetching tiles working
  //        - adjust the tile grid to match the mosaic extent
  const newSource = new XYZSource({
    crossOrigin: 'Anonymous',
    tileSize: [imageSource.tileSize, imageSource.tileSize],
    minZoom: 0,
    maxZoom: imageSource.maxZoom,
    tileGrid: new TileGrid({
      extent: imageSource.mosaicExtent,
      resolutions: new Array(imageSource.maxZoom + 1).fill(0).map((_, i) => Math.pow(2, i)).reverse(),
      tileSize: [imageSource.tileSize, imageSource.tileSize],
    }),
    interpolate: false,
    transition: 0,
    wrapX: false,
    projection,
    tileUrlFunction: ([x, y, z]: TileCoord): string => {
      if (imageSource.type === ClemexMosaicSourceType.MASK || imageSource.type === ClemexMosaicSourceType.MASK_SOURCE) {
        const [r, g, b] = maskGroupLayerColor[imageSource.maskIndex]
        return `${imageSource.getUrl(x, y, z)}?r=${r}&g=${g}&b=${b}`
      } else {
        return imageSource.getUrl(x, y, z)
      }
    },
    reprojectionErrorThreshold: 0,
  })
  newSource.setTileLoadFunction((tile: Tile, src: string): void => {
    const imageTile = tile as ImageTile
    void fetch(src)
      .then(async (response): Promise<Blob> => {
        if (RETRY_CODES.includes(response.status)) {
          retries[src] = (retries[src] ?? 0) + 1
          const maxRetryForStatusCode = getMaxRetry(response.status)
          if (retries[src] <= maxRetryForStatusCode) {
            const retryDelayForStatusCode = retries[src] * getDelayFactor(response.status) + getDelayBase(response.status)
            setTimeout(() => {
              imageTile.load()
            }, retryDelayForStatusCode)
          } else {
            console.error(`Failed to load tile ${src} (retries: ${retries[src]}) - max retries reached`)
          }
          return await Promise.reject(new Error(`Failed to load tile, retrying... (${retries[src]})`))
        }
        if (!response.ok) {
          return await Promise.reject(new Error(`Failed to load tile ${src} (retries: ${retries[src]}) - ${response.status} ${response.statusText}`))
        }
        return await response.blob()
      })
      .then((blob) => {
        const image = imageTile.getImage() as HTMLImageElement
        const imageUrl = URL.createObjectURL(blob)
        image.src = imageUrl
        const onLoadOrError = (): void => {
          URL.revokeObjectURL(imageUrl)
          image.removeEventListener('load', onLoadOrError)
          image.removeEventListener('error', onLoadOrError)
        }
        image.addEventListener('load', onLoadOrError, { once: true })
        image.addEventListener('error', onLoadOrError, { once: true })
      })
      .catch(() => {
        tile.setState(3)
      }) // 3 means error state
  })

  const layer = new WebGLTileLayer({
    sources: [newSource as unknown as ImageTileSource],
    preload: Infinity,
    useInterimTilesOnError: false,
  })

  return layer
}
