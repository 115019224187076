import Transform, { type olExtStyle, type ScaleEvent, type TranslateEvent } from 'ol-ext/interaction/Transform'
import type BaseEvent from 'ol/events/Event'
import { type TransformInteractionStyle } from '../shapes/common'
import { type Map, type MapBrowserEvent, type Collection, type Feature } from 'ol'
import { type Polygon } from 'ol/geom'
import { type ChangePatch } from './common'
import { buildCircleDirectMesureTransformInteractionStyle, buildCirclePolygon, type CircleGeometryProperties } from '../shapes/circle'
import { always, never } from 'ol/events/condition'
import { type Style } from 'ol/style'
import { type Layer } from 'ol/layer'

interface ModifyCircleConstructor {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  pixelTolerance?: number
  style?: TransformInteractionStyle
}

export class ModifyCircleDirectMeasureInteraction extends Transform {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _pixelTolerance: number
  private readonly _layers: Layer[]
  private _style: TransformInteractionStyle

  constructor ({
    layers,
    modifiableFeatures,
    onEndInteraction,
    style = buildCircleDirectMesureTransformInteractionStyle(),
    pixelTolerance = 8,
  }: ModifyCircleConstructor) {
    super({
      features: modifiableFeatures,
      layers,
      style,
      enableRotatedTransform: false,
      hitTolerance: pixelTolerance,
      keepRectangle: false,
      keepAspectRatio: always,
      rotate: true,
      translate: true,
      stretch: true,
      scale: true,
      selection: false,
      addCondition: never,
      noFlip: true,
      translateBBox: true,
      translateFeature: true,
      pointRadius: 10,
      modifyCenter: never,
    })
    this._layers = layers
    this._onEndInteraction = onEndInteraction
    this._pixelTolerance = pixelTolerance
    this._style = style

    this.addEventListener('scaleend', this._onEndEvent)
    this.addEventListener('translateend', this._onEndEvent)

    this.addEventListener('scaling', this._onScale)
    this.addEventListener('translating', this._onTranslate)
  }

  private readonly _onEndEvent = (_evt: Event | BaseEvent): void => {
    const evt = _evt as ScaleEvent | TranslateEvent
    const circlePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    this._onEndInteraction({
      update: [{
        id: circlePointFeature.getProperties().id,
        data: circlePointFeature,
      }],
    })
  }

  private readonly _onScale = (_evt: Event | BaseEvent): void => {
    const evt = _evt as ScaleEvent
    const circlePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    const geometry = circlePointFeature.getGeometry()
    if (geometry === undefined) {
      return
    }
    const geomProperties = geometry.getProperties() as CircleGeometryProperties
    const newRadius = geomProperties.radius * evt.scale[0]
    const newGeometryProperties = {
      ...geomProperties,
      radius: newRadius,
    }
    geometry.setProperties(newGeometryProperties)
    geometry.setCoordinates(buildCirclePolygon(newGeometryProperties.center, newGeometryProperties.radius).getCoordinates())
  }

  private readonly _onTranslate = (_evt: Event | BaseEvent): void => {
    const evt = _evt as TranslateEvent
    const circlePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    const geometry = circlePointFeature.getGeometry()
    if (geometry === undefined) {
      return
    }
    const geomProperties = geometry.getProperties() as CircleGeometryProperties
    const newCenter = [
      geomProperties.center[0] + evt.delta[0],
      geomProperties.center[1] + evt.delta[1],
    ] as [number, number]
    const newGeometryProperties = {
      ...geomProperties,
      center: newCenter,
    }
    geometry.setProperties(newGeometryProperties)
    geometry.setCoordinates(buildCirclePolygon(newGeometryProperties.center, newGeometryProperties.radius).getCoordinates())
  }

  public readonly setMap = (map: Map): void => {
    // XXX: `Transform.setMap` reset the style to the default one as a side-effect
    //      so we need to re-apply the style after calling super.setMap
    // Note: `setMap` is called when the interaction is added to the map
    super.setMap(map)
    this._updateStyle()
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  public readonly _updateStyle = (): void => {
    Object.entries(this._style).forEach(([key, value]) => {
      super.setStyle(key as olExtStyle, value as Style | Style[])
    })
  }

  public readonly setTransformStyle = (style: TransformInteractionStyle): void => {
    this._style = style
    this._updateStyle()
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('scaleend', this._onEndEvent)
    this.removeEventListener('translateend', this._onEndEvent)
    this.removeEventListener('scaling', this._onScale)
    super.disposeInternal()
  }
}
