import { Draw } from 'ol/interaction'
import { type Color } from '../color'
import GeoJSON from 'ol/format/GeoJSON'
import buffer from '@turf/buffer'
import { toMercator, toWgs84 } from '@turf/projection'
import { type MapBrowserEvent, type Collection, type Feature } from 'ol'
import { type Polygon as GeoJSONPolygon, type Feature as GeoJSONFeature, type LineString as GeoJSONLineString } from 'geojson'
import { type Polygon, type LineString } from 'ol/geom'
import { type PointCoordType, type DrawEvent } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { type ChangePatch } from './common'
import { type DefaultClassAnnotationProperties, type ClassAnnotations, type ClassAnnotationProperties } from '../class-annotations'

export interface BrushParameters {
  color: Color
  width: number
  eraseOtherClassesOnOverlap: boolean
  annotationPixelResolution: number
  minAnnotationArea: number
}

export class BrushInterraction<AP extends DefaultClassAnnotationProperties = DefaultClassAnnotationProperties> extends Draw {
  private _brushParameters: BrushParameters
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _onStartInteraction: () => void
  private readonly _annotations: ClassAnnotations<AP>
  private readonly _collection:  Collection<Feature<Polygon>>

  private _zoomFactor: number
  private readonly _getExtent: () => [number, number, number, number] | undefined
  private _isInteracting = false

  constructor (
    zoomFactor: number,
    collection: Collection<Feature<Polygon>>,
    brushParameters: BrushParameters,
    annotations: ClassAnnotations<AP>,
    getExtent: () => [number, number, number, number] | undefined,
    onStartInteraction: () => void,
    onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void,
  ) {
    super({
      type: 'LineString',
      freehandCondition: (mapBrowserEvent) => {
        // XXX: We use freehandCondition instead of condition to trigger the start of the drawing
        //      See `condition` for more details
        //
        // Only allow freehand drawing with left click
        // Note: pointerdrag events have a value of -1 for `button`
        //       pointerdrag events need to be registered to allow freehand drawing and collect the points
        //       pointerdown event is the event that trigger the drawing
        return mapBrowserEvent.originalEvent.button < 1
      },
      condition: () => {
        // XXX: This prevent middle click to trigger the interaction
        //      This we can use middle click to pan the map
        return false
      },
    });
    // XXX: This is a hack to disable the click tolerance
    //      Draw constructor square the click tolerance
    //      So it is not possible to set it to the negative value
    //      The hack is to set the private property to the negative value
    (this as unknown as { squaredClickTolerance_: number }).squaredClickTolerance_ = -1
    this._zoomFactor = zoomFactor
    this._brushParameters = brushParameters
    this._onEndInteraction = onEndInteraction
    this._onStartInteraction = onStartInteraction
    this._annotations = annotations
    this._collection = collection
    this._getExtent = getExtent


    this.addEventListener('drawstart', this._onDrawStart)
    this.addEventListener('drawabort', this._onDrawAbort)
    this.addEventListener('drawend', this._onDrawEnd)
    this._updateStyle()
  }

  private readonly _onDrawStart = (): void => {
    this._isInteracting = true
    this._onStartInteraction()
  }
  private readonly _onDrawAbort = () => {
    this._isInteracting = false
  }

  private readonly _onDrawEnd = (e: Event | BaseEvent): void => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    const extent = this._getExtent()
    if (extent === undefined) {
      console.error('Failed to get extends')
      return
    }

    const map = this.getMap()
    if (map === undefined || map === null) {
      console.error('Failed to get map')
      return
    }
    const view = map.getView()
    const zoom = view.getZoom()
    const zoomAtResolution1 = view.getZoomForResolution(1)
    if (zoom === undefined || zoomAtResolution1 === undefined) {
      throw new Error('Failed to zooms')
    }

    // Convert the drawn line to a buffer feature
    const olFeatureLineStringToBuffer = drawEndEvent.feature as Feature<LineString>
    const zoomFactor: number = Math.pow(this._zoomFactor, zoomAtResolution1 - zoom)

    const geojsonParser = new GeoJSON()
    const serializedStringFeatureLineStringToBuffer = geojsonParser.writeFeature(olFeatureLineStringToBuffer)
    const turfWgs84FeatureLineString = toWgs84(JSON.parse(serializedStringFeatureLineStringToBuffer) as GeoJSONFeature<GeoJSONLineString>)
    // Note: `this._brushParameters.width` is the diameter, thus the bufferRadius need to be divided by 2
    const bufferRadius = this._brushParameters.width * zoomFactor / 2
    const turfWgs84FeaturePolygon = buffer(
      turfWgs84FeatureLineString,
      bufferRadius,
      { units: 'meters' },
    ) as GeoJSONFeature<GeoJSONPolygon>

    // Simplify the polygon to avoid too many points
    const olMercatorDrawnFeaturePolygon = geojsonParser.readFeature(toMercator(turfWgs84FeaturePolygon)) as Feature<Polygon>
    olMercatorDrawnFeaturePolygon.setGeometry(olMercatorDrawnFeaturePolygon.getGeometry()?.simplify(this._brushParameters.annotationPixelResolution) as Polygon)

    this._annotations.featuresUpdateAnnotations([olMercatorDrawnFeaturePolygon])

    this._onEndInteraction({
      // TODO: Generate a patch correctly
      add: this._collection.getArray().map((feature) => {
        return {
          id: (feature.getProperties() as ClassAnnotationProperties).classAnnotationId,
          data: feature,
        }
      }),
    })
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setBrushParameters = (brushParameters: BrushParameters): void => {
    this._brushParameters = brushParameters
    this._updateStyle()
  }

  public readonly setZoomFactor = (zoomFactor: number): void => {
    this._zoomFactor = zoomFactor
  }

  private readonly _updateStyle = (): void => {
    this.getOverlay().setStyle({
      'stroke-color': this._brushParameters.color,
      'stroke-width': this._brushParameters.width,
    })
  }

  protected handleDownEvent (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean {
    const result = super.handleDownEvent(mapBrowserEvent)
    if (result) {
      const coordinate = mapBrowserEvent.coordinate;
      // XXX: Add the first point to the drawing
      //      This is needed to allow the user to draw a single point
      //      Unfortunately, `addToDrawing_` is a private method
      //      This is a hack to call it
      //      This needs to be done twice as openlayers remove the last coordinate
      (this as unknown as { addToDrawing_: (coordinate: PointCoordType) => void }).addToDrawing_(coordinate);
      (this as unknown as { addToDrawing_: (coordinate: PointCoordType) => void }).addToDrawing_(coordinate)
    }
    return result
  }

  // XXX: This is a hack to fix some issue with aborting/finishing the drawing when switching between tools
  public readonly abortDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  public readonly finishDrawingDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this._onDrawStart)
    this.removeEventListener('drawabort', this._onDrawAbort)
    this.removeEventListener('drawend', this._onDrawEnd)
    super.disposeInternal()
  }
}
