import * as React from 'react'
import { Button, Typography, Row, Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import UploadIcon from '@material-design-icons/svg/outlined/file_upload.svg'
import AddIcon from '@material-design-icons/svg/filled/add.svg'
import styles from './styles/left-panel.module.scss'
import { TOOLTIP_MOUSE_ENTER_DELAY, MESSAGE_UPLOAD_IMAGE_DIMENSION, MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT } from '@app/constants'

const { Title, Text } = Typography


interface LeftPanelElementProps {
  underline?: boolean
  topLine?: boolean
  expand?: boolean
  shrinkable?: boolean
  separator?: boolean
  children: React.ReactNode
  grow?: boolean
  className?: string
}
export const LeftPanelElement: React.FC<LeftPanelElementProps> = ({ underline, topLine, expand, shrinkable, separator, grow, children, className }) => {
  return <Row
    className={clsx(
      className,
      styles.panelRow,
      {
        [styles.underline]: underline,
        [styles.topLine]: topLine,
        [styles.expand]: expand,
        [styles.shrinkable]: shrinkable,
        [styles.smallSeparator]: separator,
        [styles.grow]: grow,
      },
    )}
  >
    {children}
  </Row>
}

export const LeftPanelGroup: React.FC<{ children?: React.ReactNode, restrictedHeight?: boolean }> = ({ children, restrictedHeight }) => {
  return <div className={clsx(
    styles.panelGroup,
    {
      [styles.restrictedHeight]: restrictedHeight,
    },
  )}>
    {children}
  </div>
}

export const DropZoneText: React.FC = () => {
  return <div className={styles.dropZone}>
    <div className={styles.dropZoneIcon}>
      <UploadIcon />
    </div>
    <Text className={styles.dropZoneLabel}>
      <FormattedMessage id="editor.add-image.dropzone.label" defaultMessage={'Release here to upload images'} />
      <br/>
      { MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT }
      <br/>
      { MESSAGE_UPLOAD_IMAGE_DIMENSION }
    </Text>
  </div>
}

interface UploadImageSectionProps {
  addImage: () => void
}
export const UploadImageSection: React.FC<UploadImageSectionProps> = ({ addImage }) => {
  return <div className={styles.addImage} id='upload-image-section'>
    <div className={styles.addImageHeader}>
      <Title level={5} className={styles.addImageTitle}>
        <FormattedMessage id="editor.left-panel.title" defaultMessage={'Images'} />
      </Title>
      <Tooltip
        title={
          <FormattedMessage id={'left-panel.images.button.add'} defaultMessage={'Upload images'} />
        }
        mouseLeaveDelay={0}
        mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
      >
        <Button
          className={styles.addImageButton}
          icon={<AddIcon />}
          onClick={() => {
            const element = document.activeElement as HTMLElement
            addImage()
            element.blur()
          }}
        />
      </Tooltip>
    </div>
  </div>
}

export const LeftPanel: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <div className={styles.leftPanel}>
    {children}
  </div>
}
