import type BaseEvent from 'ol/events/Event'
import { Draw } from 'ol/interaction'
import { type DrawEvent } from 'ol/interaction/Draw'
import { v4 as uuidV4 } from 'uuid'
import { FEATURE_TYPE, FeatureType, type TransformInteractionStyle } from '../shapes/common'
import { type ChangePatch } from './common'
import { type Collection, type Map, type Feature, type MapBrowserEvent } from 'ol'
import { Polygon } from 'ol/geom'
import EventType from 'ol/events/EventType'
import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Style from 'ol/style/Style'
import { type StyleFunction } from 'ol/style/Style'
import { buildEllipseDirectMesureTransformInteractionStyle, buildEllipsePolygon, type EllipseGeometryProperties } from '../shapes/ellipse'
import Transform, { type RotateEvent, type olExtStyle, type ScaleEvent, type TranslateEvent } from 'ol-ext/interaction/Transform'
import { never, primaryAction } from 'ol/events/condition'
import { buildCirclePolygon } from '../shapes/circle'
import { type Layer } from 'ol/layer'

interface DrawEllipseInteractionConstructor {
  onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  circleDestinationCollection: Collection<Feature<Polygon>>
  ellipseDestinationCollection: Collection<Feature<Polygon>>
  style?: StyleFunction
  isDirectMeasure: boolean
  filled: boolean
}

export class DrawEllipseInteraction extends Draw {
  private _isInteracting = false
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private _forceCircle = false
  private readonly _isDirectMeasure: boolean
  private _filled: boolean
  private _feature: Feature<Polygon> | null = null
  private readonly _ellipseDestinationCollection: Collection<Feature<Polygon>>
  private readonly _circleDestinationCollection: Collection<Feature<Polygon>>

  constructor ({
    circleDestinationCollection,
    ellipseDestinationCollection,
    onEndInteraction,
    style,
    isDirectMeasure,
    filled,
  }: DrawEllipseInteractionConstructor) {
    super({
      type: 'LineString',
      minPoints: 2,
      maxPoints: 2,
      condition: primaryAction,
      freehandCondition: (e) => {
        if (e.originalEvent instanceof MouseEvent) {
          return e.originalEvent.button === -1 || e.originalEvent.button === 0
        }
        return true
      },
      geometryFunction: (coordinates_, geometry) => {
        if (geometry === undefined) {
          geometry = new Polygon([])
        }
        const coordinates = coordinates_ as [number, number][]
        if (coordinates.length === 2) {
          const start = coordinates[0]
          const end = coordinates[coordinates.length - 1]
          const center = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2] as [number, number]
          const radiusX = Math.abs(start[0] - end[0]) / 2
          const radiusY = Math.abs(start[1] - end[1]) / 2
          const radius = Math.max(radiusX, radiusY)
          // XXX: always set the properties of the circle, even for ellipse
          //      this allows to convert to circle if the user press the shift key
          geometry.setProperties({
            radius,
            center,
            filled: this._filled,
            pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
          })
          geometry.setProperties({
            radiusX,
            radiusY,
            angle: 0,
            center,
            filled: this._filled,
            pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
          })
          if (this._forceCircle) {
            geometry.setProperties({ geometryType: 'CIRCLE' })
            const circle = buildCirclePolygon(center, radius)
            geometry.setCoordinates(circle.getCoordinates())
          } else {
            geometry.setProperties({ geometryType: 'ELLIPSE' })
            const ellipse = buildEllipsePolygon(center, Math.abs(start[0] - end[0]) / 2, Math.abs(start[1] - end[1]) / 2, 0)
            geometry.setCoordinates(ellipse.getCoordinates())
          }
        }
        return geometry
      },
      style,
    })

    this._onEndInteraction = onEndInteraction
    this._isDirectMeasure = isDirectMeasure
    this._filled = filled
    this._ellipseDestinationCollection = ellipseDestinationCollection
    this._circleDestinationCollection = circleDestinationCollection

    this.addEventListener('drawstart', this._onDrawStart)
    this.addEventListener('drawabort', this._onDrawAbort)
    this.addEventListener('drawend', this._onDrawEnd)
  }

  private readonly _onDrawStart = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    const id = uuidV4()
    drawEndEvent.feature.setProperties({
      [FEATURE_TYPE]: this._getFeatureType(),
      id,
    })
    this._isInteracting = true
    this._feature = drawEndEvent.feature as Feature<Polygon>
  }

  private readonly _onDrawAbort = () => {
    this._isInteracting = false
    this._forceCircle = false
    this._feature = null
  }

  private readonly _onDrawEnd = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    this._forceCircle = false
    this._feature = null
    const feature = drawEndEvent.feature as Feature<Polygon>
    if (this._forceCircle) {
      this._circleDestinationCollection.push(feature)
    } else {
      this._ellipseDestinationCollection.push(feature)
    }
    this._onEndInteraction({
      add: [{
        id: feature.getProperties().id,
        data: feature,
      }],
    })
  }

  private readonly _getFeatureType = (): FeatureType => {
    if (this._isDirectMeasure) {
      if (this._forceCircle) {
        return FeatureType.DIRECT_MEASURE_CIRCLE
      } else {
        return FeatureType.DIRECT_MEASURE_ELLIPSE
      }
    } else {
      if (this._forceCircle) {
        return FeatureType.METADATA_ANNOTATION_CIRCLE
      } else {
        return FeatureType.METADATA_ANNOTATION_ELLIPSE
      }
    }
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
      if (key === 'Shift') {
        this._forceCircle = true
        this._feature?.setProperties({
          [FEATURE_TYPE]: this._getFeatureType(),
        })
        this._feature?.getGeometry()?.changed()
        this._feature?.changed()
        const onKeyUp = (e: KeyboardEvent): void => {
          if (e instanceof KeyboardEvent && e.key === 'Shift') {
            this._forceCircle = false
            this._feature?.setProperties({
              [FEATURE_TYPE]: this._getFeatureType(),
            })
            this._feature?.getGeometry()?.changed()
            this._feature?.changed()
          }
        }
        mapBrowserEvent.map.getTargetElement().addEventListener('keyup', onKeyUp, { once: true })
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this._onDrawStart)
    this.removeEventListener('drawabort', this._onDrawAbort)
    this.removeEventListener('drawend', this._onDrawEnd)
    super.disposeInternal()
  }
}

interface ModifyEllipseInteractionConstructor {
  modifiableFeatures: Collection<Feature>
  layers: VectorLayer<VectorSource<Feature<Polygon>>>[]
  onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  pixelTolerance?: number
  style?: TransformInteractionStyle
}

export class ModifyEllipseInteraction extends Transform {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _pixelTolerance: number
  private readonly _layers: Layer[]
  private _style: TransformInteractionStyle

  constructor ({
    layers,
    modifiableFeatures,
    onEndInteraction,
    style = buildEllipseDirectMesureTransformInteractionStyle(),
    pixelTolerance = 8,
  }: ModifyEllipseInteractionConstructor) {
    super({
      features: modifiableFeatures,
      layers,
      style,
      enableRotatedTransform: false,
      hitTolerance: pixelTolerance,
      keepRectangle: true,
      rotate: true,
      translate: true,
      stretch: true,
      scale: true,
      selection: false,
      addCondition: never,
      noFlip: true,
      translateBBox: true,
      translateFeature: true,
      pointRadius: 10,
      modifyCenter: never,
    })
    this._layers = layers
    this._onEndInteraction = onEndInteraction
    this._pixelTolerance = pixelTolerance
    this._style = style

    this.addEventListener('scaleend', this._onEndEvent)
    this.addEventListener('translateend', this._onEndEvent)
    this.addEventListener('rotateend', this._onEndEvent)

    this.addEventListener('scaling', this._onScale)
    this.addEventListener('rotating', this._onRotate)
    this.addEventListener('translating', this._onTranslate)
  }

  private readonly _onEndEvent = (_evt: Event | BaseEvent): void => {
    const evt = _evt as ScaleEvent | TranslateEvent | RotateEvent
    const ellipsePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    this._onEndInteraction({
      update: [{
        id: ellipsePointFeature.getProperties().id,
        data: ellipsePointFeature,
      }],
    })
  }

  private readonly _onScale = (_evt: Event | BaseEvent): void => {
    const evt = _evt as ScaleEvent
    const ellipsePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    const geometry = ellipsePointFeature.getGeometry()
    if (geometry === undefined) {
      return
    }
    const geomProperties = geometry.getProperties() as EllipseGeometryProperties
    const {
      radiusX,
      radiusY,
      angle,
      center,
    } = geomProperties
    const newAngle = angle
    const A = [
      radiusX * Math.cos(angle) * evt.scale[0],
      radiusX * Math.sin(angle) * evt.scale[1],
    ]
    const B = [
      radiusY * Math.cos(angle + Math.PI / 2) * evt.scale[0],
      radiusY * Math.sin(angle + Math.PI / 2) * evt.scale[1],
    ]
    const newRadiusX = Math.sqrt(A[0] ** 2 + A[1] ** 2)
    const newRadiusY = Math.sqrt(B[0] ** 2 + B[1] ** 2)
    const newGeometryProperties = {
      ...geomProperties,
      angle: newAngle,
      radiusX: newRadiusX,
      radiusY: newRadiusY,
      center,
    }
    geometry.setProperties(newGeometryProperties)
    geometry.setCoordinates(buildEllipsePolygon(newGeometryProperties.center, newGeometryProperties.radiusX, newGeometryProperties.radiusY, newGeometryProperties.angle).getCoordinates())
  }

  private readonly _onRotate = (_evt: Event | BaseEvent): void => {
    const evt = _evt as RotateEvent
    const ellipsePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    const geometry = ellipsePointFeature.getGeometry()
    if (geometry === undefined) {
      return
    }
    const geomProperties = geometry.getProperties() as EllipseGeometryProperties
    const newGeometryProperties = {
      ...geomProperties,
      angle: geomProperties.angle + evt.angle,
    } satisfies EllipseGeometryProperties
    geometry.setProperties(newGeometryProperties)
    geometry.setCoordinates(buildEllipsePolygon(newGeometryProperties.center, newGeometryProperties.radiusX, newGeometryProperties.radiusY, newGeometryProperties.angle).getCoordinates())
  }

  private readonly _onTranslate = (_evt: Event | BaseEvent): void => {
    const evt = _evt as TranslateEvent
    const ellipsePointFeature = evt.features.getArray()[0] as Feature<Polygon>
    const geometry = ellipsePointFeature.getGeometry()
    if (geometry === undefined) {
      return
    }
    const geomProperties = geometry.getProperties() as EllipseGeometryProperties
    const { center } = geomProperties
    const newCenter = [
      center[0] + evt.delta[0],
      center[1] + evt.delta[1],
    ] as [number, number]
    const newGeometryProperties = {
      ...geomProperties,
      center: newCenter,
    } satisfies EllipseGeometryProperties
    geometry.setProperties(newGeometryProperties)
    geometry.setCoordinates(buildEllipsePolygon(newGeometryProperties.center, newGeometryProperties.radiusX, newGeometryProperties.radiusY, newGeometryProperties.angle).getCoordinates())
  }

  public readonly setMap = (map: Map): void => {
    // XXX: `Transform.setMap` reset the style to the default one as a side-effect
    //      so we need to re-apply the style after calling super.setMap
    // Note: `setMap` is called when the interaction is added to the map
    super.setMap(map)
    this._updateStyle()
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  public readonly _updateStyle = (): void => {
    Object.entries(this._style).forEach(([key, value]) => {
      super.setStyle(key as olExtStyle, value as Style | Style[])
    })
  }

  public readonly setTransformStyle = (style: TransformInteractionStyle): void => {
    this._style = style
    this._updateStyle()
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('scaleend', this._onEndEvent)
    this.removeEventListener('translateend', this._onEndEvent)
    this.removeEventListener('rotateend', this._onEndEvent)
    this.removeEventListener('scaling', this._onScale)
    this.removeEventListener('rotating', this._onRotate)
    this.removeEventListener('translating', this._onTranslate)
    super.disposeInternal()
  }
}
