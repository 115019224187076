import { Draw } from 'ol/interaction'
import { type LineString, type Polygon } from 'ol/geom'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { type MapBrowserEvent, type Collection, type Feature } from 'ol'
import { toMercator, toWgs84 } from '@turf/projection'
import GeoJSON from 'ol/format/GeoJSON'
import difference from '@turf/difference'
import { type Polygon as GeoJSONPolygon, type Feature as GeoJSONFeature, type LineString as GeoJSONLineString, type MultiPolygon as GeoJSONMultiPolygon, type FeatureCollection as GeoJSONFeatureCollection } from 'geojson'

import flatten from '@turf/flatten'
import buffer from '@turf/buffer'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import { getVectorContext } from 'ol/render'
import { type DrawEvent, type PointCoordType } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { makePolygonValid } from '../utils'
import { type ChangePatch } from './common'
import { v4 as uuidV4 } from 'uuid'
import { featureCollection } from '@turf/helpers'
import RenderEvent from 'ol/render/Event'

interface EraserParameters {
  width: number
  eraserOpacity: number
  annotationPixelResolution: number
  minAnnotationArea: number
}
export class EraserInterraction extends Draw {
  private _eraserParameters: EraserParameters
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _onStartInteraction: () => void
  public readonly tempLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  public readonly tempSource: VectorSource<Feature<Polygon>>
  private readonly destinationLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly collection: Collection<Feature<Polygon>>
  private _zoomFactor: number
  private _isInteracting = false

  constructor (
    zoomFactor: number,
    destinationLayer: VectorLayer<VectorSource<Feature<Polygon>>>,
    collection: Collection<Feature<Polygon>>,
    eraserParameters: EraserParameters,
    onStartInteraction: () => void,
    onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void,
  ) {
    super({
      type: 'LineString',
      freehandCondition: (mapBrowserEvent) => {
        // Only allow freehand drawing with left click
        // Note: pointerdrag events have a value of -1 for `button`
        //       pointerdrag events need to be registered to allow freehand drawing and collect the points
        //       pointerdown event is the event that trigger the drawing
        return mapBrowserEvent.originalEvent.button < 1
      },
      condition: () => {
        // Only allow freehand drawing
        // This prevent middle click to trigger the interaction
        return false
      },
    });
    // XXX: This is a hack to disable the click tolerance
    //      Draw constructor square the click tolerance
    //      So it is not possible to set it to the negative value
    //      The hack is to set the private property to the negative value
    (this as unknown as { squaredClickTolerance_: number }).squaredClickTolerance_ = -1
    this._zoomFactor = zoomFactor
    this._eraserParameters = eraserParameters
    this._onEndInteraction = onEndInteraction
    this._onStartInteraction = onStartInteraction
    this.destinationLayer = destinationLayer
    this.collection = collection

    this.tempSource = new VectorSource<Feature<Polygon>>({})
    this.tempLayer = new VectorLayer({
      source: this.tempSource,
      zIndex: destinationLayer.getZIndex(),
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    })

    this.addEventListener('drawstart', this._onDrawStart)
    this.addEventListener('drawabort', this._onDrawAbort)
    this.addEventListener('drawend', this._onDrawEnd)

    this.tempLayer.addEventListener('postrender', this._onPostRender)

    this._updateStyle()
  }

  private readonly _onDrawStart = () => {
    this._isInteracting = true
    this._onStartInteraction()
    this.tempSource.clear(true)
    this.tempSource.addFeatures(this.collection.getArray())
    this.tempLayer.setVisible(true)
    this.destinationLayer.setVisible(false)
  }

  private readonly _onDrawAbort = () => {
    this._isInteracting = false
    this.tempSource.clear(true)
    this.tempLayer.setVisible(false)
    this.destinationLayer.setVisible(true)
  }

  private readonly _onDrawEnd = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    const map = this.getMap()
    if (map === undefined || map === null) {
      console.error('Failed to get map')
      return
    }
    const view = map.getView()
    const newFeatures: Feature<Polygon>[] = []
    const zoom = view.getZoom()
    const zoomAtResolution1 = view.getZoomForResolution(1)
    if (zoom === undefined || zoomAtResolution1 === undefined) {
      throw new Error('Failed to zooms')
    }
    const olFeatureLineStringToBuffer = drawEndEvent.feature as Feature<LineString>
    const zoomFactor: number = Math.pow(this._zoomFactor, zoomAtResolution1 - zoom)
    const geojsonParser = new GeoJSON()
    const serializedStringFeatureLineStringToBuffer = geojsonParser.writeFeature(olFeatureLineStringToBuffer)
    const turfWgs84FeatureLineString = toWgs84(JSON.parse(serializedStringFeatureLineStringToBuffer) as GeoJSONFeature<GeoJSONLineString>)
    // Note: `this._eraserParameters.width` is the diameter, thus the bufferRadius need to be divided by 2
    const bufferRadius = this._eraserParameters.width * zoomFactor / 2
    const turfWgs84FeaturePolygon = buffer(
      turfWgs84FeatureLineString,
      bufferRadius,
      { units: 'meters' },
    )

    // Simplify the polygon to avoid too many points
    const olMercatordrawnFeaturePolygon = geojsonParser.readFeature(toMercator(turfWgs84FeaturePolygon)) as Feature<Polygon>
    olMercatordrawnFeaturePolygon.setGeometry(olMercatordrawnFeaturePolygon.getGeometry()?.simplify(this._eraserParameters.annotationPixelResolution) as Polygon)
    const turfWgs84FeaturePolygonSimplified: GeoJSONFeature<GeoJSONPolygon> = toWgs84(JSON.parse(geojsonParser.writeFeature(olMercatordrawnFeaturePolygon)))

    this.collection.forEach((olMercatorAnnotationFeaturePolygon) => {
      const featureProperties = olMercatorAnnotationFeaturePolygon.getProperties()
      const turfWgs84AnnotationFeaturePolygon = toWgs84(JSON.parse(geojsonParser.writeFeature(olMercatorAnnotationFeaturePolygon)) as GeoJSONFeature<GeoJSONPolygon>)
      const diffTurfWgs84FeatureMultiPolygon: GeoJSONFeature<GeoJSONPolygon | GeoJSONMultiPolygon> | null = difference(featureCollection([
        turfWgs84AnnotationFeaturePolygon,
        turfWgs84FeaturePolygonSimplified,
      ]))
      if (diffTurfWgs84FeatureMultiPolygon !== null) {
        const diffTurfWgs84FeatureCollectionPolygon: GeoJSONFeatureCollection<GeoJSONPolygon> = flatten(diffTurfWgs84FeatureMultiPolygon)
        diffTurfWgs84FeatureCollectionPolygon.features.forEach((diffTurfWgs84FeaturePolygon) => {
          const diffTurfMercatorFeaturePolygon: GeoJSONFeature<GeoJSONPolygon> = toMercator(diffTurfWgs84FeaturePolygon)
          const diffOlMercatorFeaturePolygon = geojsonParser.readFeature(diffTurfMercatorFeaturePolygon) as Feature<Polygon>
          const polygonGeometry = diffOlMercatorFeaturePolygon.getGeometry()
          if (polygonGeometry === null || polygonGeometry === undefined) {
            console.error(`Unexpected nullish polygon geometry (${polygonGeometry})`)
          } else {
            const validPolygon = makePolygonValid(polygonGeometry)
            if (validPolygon !== undefined) {
              // The new feature should inherit the properties of the original feature
              // Note: setProperties should be used carefully, as it can override any attributes including the geometry/coordinates
              diffOlMercatorFeaturePolygon.setProperties({
                featureProperties,
                coord: undefined,
                classAnnotationId: uuidV4(),
              })
              diffOlMercatorFeaturePolygon.setGeometry(polygonGeometry)
              newFeatures.push(diffOlMercatorFeaturePolygon)
            } else {
              diffOlMercatorFeaturePolygon.dispose()
            }
          }
        })
      }
    })
    this.collection.clear()
    this.collection.extend(newFeatures)

    this.destinationLayer.setVisible(true)

    this.tempSource.clear(true)
    this.tempLayer.setVisible(false)
    this._onEndInteraction({
      // TODO: Generate a patch correctly
      add: newFeatures.map((f) => {
        const id = uuidV4()
        f.setProperties({ classAnnotationId: id })
        return {
          id,
          data: f,
        }
      }),
    })
  }

  private readonly _onPostRender = (evt: Event | BaseEvent) => {
    const preRenderEvent = evt as RenderEvent
    const canvasContext = preRenderEvent.context as CanvasRenderingContext2D
    const vectorContext = getVectorContext(preRenderEvent)
    const resolution = preRenderEvent.frameState?.viewState.resolution ?? 0
    this.collection.getArray().forEach((feature) => {
      const styleFunction = feature.getStyleFunction() ?? this.destinationLayer.getStyleFunction()

      const styles = (styleFunction?.(feature, resolution)) ?? feature.getStyle() ?? this.destinationLayer.getStyle()
      if (styles !== null && styles !== undefined) {
        if (Array.isArray(styles)) {
          styles.forEach((style) => {
            vectorContext.drawFeature(feature, style as Style)
          })
        } else if (styles instanceof Style) {
          vectorContext.drawFeature(feature, styles)
        } else {
          console.error('Failed to get style')
        }
      }
    })
    const saveGlobalCompositeOperation = canvasContext.globalCompositeOperation
    canvasContext.globalCompositeOperation = 'destination-out'
    this.getOverlay().getSource()?.getFeatures().forEach((feature: Feature<Polygon>) => {
      vectorContext.drawFeature(feature, new Style({
        stroke: new Stroke({
          // Note: this assume that the zoom level did not change since the beggining of the interraction.
          width: this._eraserParameters.width,
          color: [255, 255, 255, this._eraserParameters.eraserOpacity],
        }),
      }))
    })
    canvasContext.globalCompositeOperation = saveGlobalCompositeOperation
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setEraserParameters = (eraserParameters: EraserParameters): void => {
    this._eraserParameters = eraserParameters
    this._updateStyle()
  }

  public readonly setZoomFactor = (zoomFactor: number): void => {
    this._zoomFactor = zoomFactor
  }

  private readonly _updateStyle = (): void => {
    this.getOverlay().setStyle({
      'stroke-width': this._eraserParameters.width,
      'stroke-color': 'rgba(0, 0, 0, 0)',
    })
  }

  protected handleDownEvent (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean {
    const result = super.handleDownEvent(mapBrowserEvent)
    if (result) {
      const coordinate = mapBrowserEvent.coordinate;
      // XXX: Add the first point to the drawing
      //      This is needed to allow the user to draw a single point
      //      Unfortunately, `addToDrawing_` is a private method
      //      This is a hack to call it
      //      This needs to be done twice as openlayers remove the last coordinate
      (this as unknown as { addToDrawing_: (coordinate: PointCoordType) => void }).addToDrawing_(coordinate);
      (this as unknown as { addToDrawing_: (coordinate: PointCoordType) => void }).addToDrawing_(coordinate)
    }
    return result
  }

  // XXX: This is a hack to fix some issue with aborting/finishing the drawing when switching between tools
  public readonly abortDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  public readonly finishDrawingDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this._onDrawStart)
    this.removeEventListener('drawabort', this._onDrawAbort)
    this.removeEventListener('drawend', this._onDrawEnd)
    this.tempLayer.removeEventListener('postrender', this._onPostRender)
    super.disposeInternal()
  }
}
