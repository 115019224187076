import { LineString } from 'ol/geom'
import { Draw, Modify, Translate } from 'ol/interaction'
import { type Map, type Collection, type Feature, type MapBrowserEvent } from 'ol'
import { type DrawEvent } from 'ol/interaction/Draw'
import EventType from 'ol/events/EventType'
import type BaseEvent from 'ol/events/Event'
import { FEATURE_TYPE, type ArcFeatureType } from '../shapes/common'
import { type ChangePatch } from './common'
import { v4 as uuidV4 } from 'uuid'
import { type ModifyEvent } from 'ol/interaction/Modify'
import { type TranslateEvent } from 'ol/interaction/Translate'
import { type StyleFunction } from 'ol/style/Style'
import { primaryAction } from 'ol/events/condition'
import { type Layer } from 'ol/layer'
import { type FeatureLike } from 'ol/Feature'
import { type Coordinate } from 'ol/coordinate'
import { type ArcGeometryProperties } from '../shapes/arc'

export class DrawArcInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _destinationCollection: Collection<Feature<LineString>>
  private _isInteracting = false
  private readonly _drawingFeatureType: ArcFeatureType
  private _filled: boolean

  constructor (
    destinationCollection: Collection<Feature<LineString>>,
    style: StyleFunction,
    onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void,
    drawingFeatureType: ArcFeatureType,
    filled = false,
  ) {
    super({
      type: 'LineString',
      minPoints: 3,
      maxPoints: 3,
      style,
      geometryFunction: (coordinates_, geom) => {
        const coordinates = coordinates_ as [number, number][]
        if (geom === undefined) {
          geom = new LineString(coordinates)
        } else {
          geom.setCoordinates(coordinates)
        }
        geom?.setProperties({
          geometryType: 'ARC',
          pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
          filled: this._filled,
        } satisfies ArcGeometryProperties)
        return geom
      },
    })
    this._onEndInteraction = onEndInteraction
    this._destinationCollection = destinationCollection
    this._drawingFeatureType = drawingFeatureType
    this._filled = filled

    this.addEventListener('drawstart', this.onDrawStart)
    this.addEventListener('drawabort', this.onDrawAbort)
    this.addEventListener('drawend', this.onDrawEnd)
  }

  private readonly onDrawStart = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    const id = uuidV4()
    drawEndEvent.feature.setProperties({
      [FEATURE_TYPE]: this._drawingFeatureType,
      id,
    })
    this._isInteracting = true
  }
  private readonly onDrawAbort = () => {
    this._isInteracting = false
  }
  private readonly onDrawEnd = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    const feature = drawEndEvent.feature as Feature<LineString>
    this._destinationCollection.push(feature)
    this._onEndInteraction({
      add: [{
        id: feature.getProperties().id,
        data: feature,
      }],
    })
  }


  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this.onDrawStart)
    this.removeEventListener('drawabort', this.onDrawAbort)
    this.removeEventListener('drawend', this.onDrawEnd)
    super.disposeInternal()
  }
}

interface ModifyArcInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  onStartInteraction: (targetFeature: Feature<LineString>[]) => void
  style: StyleFunction
  pixelTolerance?: number
}

export class ModifyArcInteraction extends Modify {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _onStartInteraction: (targetFeature: Feature<LineString>[]) => void
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number

  constructor ({
    modifiableFeatures,
    layers,
    onEndInteraction,
    onStartInteraction,
    style,
    pixelTolerance = 8,
  }: ModifyArcInteractionOptions) {
    super({
      features: modifiableFeatures,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      pixelTolerance,
      insertVertexCondition: () => {
        return false
      },
      style,
    })
    this._onEndInteraction = onEndInteraction
    this._onStartInteraction = onStartInteraction
    this._pixelTolerance = pixelTolerance
    this._layers = layers

    this.addEventListener('modifystart', this._onModifyStart)

    this.addEventListener('modifyend', this._onModifyEnd)
  }

  private readonly _onModifyStart = (_modifyStartEvent: Event | BaseEvent) => {
    const modifyStartEvent = _modifyStartEvent as ModifyEvent
    this._onStartInteraction(modifyStartEvent.features.getArray() as Feature<LineString>[])
  }

  private readonly _onModifyEnd = (_modifyEndEvent: Event | BaseEvent) => {
    const modifyEndEvent = _modifyEndEvent as ModifyEvent
    this._onEndInteraction({
      update: modifyEndEvent.features.getArray().map((feature) => {
        return {
          id: feature.getProperties().id,
          data: feature as Feature<LineString>,
        }
      }),
    })
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('modifyStart', this._onModifyStart)
    this.removeEventListener('modifyEnd', this._onModifyEnd)
    super.disposeInternal()
  }
}

interface TranslateArcInteractionOptions {
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  pixelTolerance?: number
}
export class TranslateArcInteraction extends Translate {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number
  private _hasTranslated = false

  constructor ({
    layers,
    onEndInteraction,
    onStartInteraction,
    pixelTolerance = 16,
  }: TranslateArcInteractionOptions) {
    super({
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      hitTolerance: pixelTolerance,
    })
    this._onEndInteraction = onEndInteraction
    this._onStartInteraction = onStartInteraction
    this._pixelTolerance = pixelTolerance
    this._layers = layers;
    (this as unknown as { featuresAtPixel_: TranslateArcInteraction['_featuresAtPixel_'] }).featuresAtPixel_ = this._featuresAtPixel_

    this.addEventListener('translatestart', this._onTranslateStart)
    this.addEventListener('translating', this._onTranslate)
    this.addEventListener('translateend', this._onTranslateEnd)
  }

  private readonly _onTranslateStart = (_translateStartEvent: Event | BaseEvent) => {
    const translateStartEvent = _translateStartEvent as TranslateEvent
    this._onStartInteraction(translateStartEvent.features.getArray() as Feature<LineString>[])
    this._hasTranslated = false
  }

  private readonly _onTranslate = () => {
    this._hasTranslated = true
  }

  private readonly _onTranslateEnd = (_translateEndEvent: Event | BaseEvent) => {
    const translateEndEvent = _translateEndEvent as TranslateEvent
    if (!this._hasTranslated) {
      this._onEndInteraction({}) // No change
    } else {
      this._onEndInteraction({
        update: translateEndEvent.features.getArray().map((feature) => {
          return {
            id: feature.getProperties().id,
            data: feature as Feature<LineString>,
          }
        }),
      })
    }
    this._hasTranslated = false
  }

  // XXX: reimplementation of the private method featuresAtPixel_ from ol/interaction/Translate
  //      The original implementation is buggy as `feature` frin the callback of forEachFeatureAtPixel
  //      can be `FeatureLike` instead of `Feature<Geometry>`.
  private readonly _featuresAtPixel_ = (pixel: Coordinate, map: Map): Feature | undefined => {
    return map.forEachFeatureAtPixel(
      pixel,
      (feature, layer) => {
        if (!(this as unknown as { filter_: (f: FeatureLike, l: Layer) => boolean }).filter_(feature, layer)) {
          return undefined
        }
        const features = (this as unknown as { features_: Collection<Feature> | null }).features_
        if ((features != null) && !features.getArray().some((f) => f.getId() === feature.getId())) {
          return undefined
        }
        return feature
      },
      {
        layerFilter: (layer) => this._layers.includes(layer),
        hitTolerance: this._pixelTolerance,
      },
    ) as Feature | undefined
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('translateStart', this._onTranslateStart)
    this.removeEventListener('translating', this._onTranslate)
    this.removeEventListener('translateEnd', this._onTranslateEnd)
    super.disposeInternal()
  }
}

export const findCenterFromTriangle = (pA: [number, number] | number[], pB: [number, number] | number[], pC: [number, number] | number[]): [number, number] => {
  // D is the middle of AB
  const pD = [(pA[0] + pB[0]) / 2, (pA[1] + pB[1]) / 2] as const
  // E is the middle of BC
  const pE = [(pB[0] + pC[0]) / 2, (pB[1] + pC[1]) / 2] as const
  // DF is the rotation of AD by 90°
  const pF = [pD[0] - (pD[1] - pA[1]), pD[1] + (pD[0] - pA[0])] as const
  // BG is the rotation of BE by 90°
  const pG = [pE[0] - (pE[1] - pB[1]), pE[1] + (pE[0] - pB[0])] as const

  // H is the intersection of the DF and EG lines
  // D + t * (F - D) = E + t * (G - E)
  // D + u * (F - D) = E + u * (G - E)
  // t = ((Dx - Ex) * (Ey - Gy) - (Dy - Ey) * (Ex - Gx)) / ((Dx - Fx) * (Ey - Gy) - (Dy - Fy) * (Ex - Gx))
  // H = D + t * (F - D)
  const t = ((pD[0] - pE[0]) * (pE[1] - pG[1]) - (pD[1] - pE[1]) * (pE[0] - pG[0])) / ((pD[0] - pF[0]) * (pE[1] - pG[1]) - (pD[1] - pF[1]) * (pE[0] - pG[0]))
  const pH: [number, number] = [
    pD[0] + (t) * (pF[0] - pD[0]),
    pD[1] + (t) * (pF[1] - pD[1]),
  ]
  return pH
}
