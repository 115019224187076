import * as React from 'react'
import { Button, notification, Tooltip } from 'antd'
import { type ArgsProps } from 'antd/lib/notification'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './notification.module.scss'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import CopyIcon from '@material-design-icons/svg/outlined/copy_all.svg'
import CopiedIcon from '@material-design-icons/svg/outlined/check.svg'
import copy from 'copy-to-clipboard'

interface NotificationProps {
  type: ArgsProps['type']
  message: ArgsProps['message']
  description: ArgsProps['description']
  duration: ArgsProps['duration']
  icon: ArgsProps['icon']
  placement: ArgsProps['placement']
  btn: ArgsProps['btn']
  className: ArgsProps['className']
  onClose: ArgsProps['onClose']
}
export const BaseNotification: React.FC<NotificationProps> = ({
  btn,
  description,
  duration,
  icon,
  message,
  onClose,
  placement,
  type,
  className,
}) => {
  const [api, notificationContextHolder] = notification.useNotification()

  React.useEffect(() => {
    api.open({
      btn,
      description,
      duration,
      icon,
      message,
      placement,
      type,
      className,
      onClose: () => {
        if (onClose !== undefined) {
          onClose()
        }
      },
    })
  }, [api, btn, description, duration, icon, message, onClose, placement, type, className])

  return notificationContextHolder
}

interface NotificationWithContactUsButtonProps {
  projectId: string
  description: React.ReactNode
  message: React.ReactNode
  duration: number // in seconds
  onClose?: () => void
}
export const NotificationWithContactUsButton: React.FC<NotificationWithContactUsButtonProps> = ({ projectId, message, description, duration, onClose }) => {
  const intl = useIntl()
  const failToTrainEmailSubjectMessageIntlMessage = intl.formatMessage(
    {
      id: 'algorithm-notification.error.mailto.template.subject',
      defaultMessage: 'I need some help [Project { projectId }]',
    },
    { projectId },
  )
  const failToTrainEmailBodyMessageIntlMessage = intl.formatMessage({
    id: 'algorithm-notification.error.mailto.template.body',
    defaultMessage: 'Could you please help me troubleshoot my issue?',
  })
  const failToTrainMailToURL = `mailto:info@clemex.com?subject=${encodeURIComponent(failToTrainEmailSubjectMessageIntlMessage)}&body=${encodeURIComponent(failToTrainEmailBodyMessageIntlMessage)}`

  const button = React.useMemo(() => {
    return <a href={failToTrainMailToURL}>
      <Button>
        <FormattedMessage
          id='algorithm-notification.error.button'
          defaultMessage='Contact us'
        />
      </Button>
    </a>
  }, [failToTrainMailToURL])

  return <BaseNotification
    type='error'
    message={message}
    icon='😿'
    description={description}
    placement='top'
    className={styles.notificationWithContactUsButtonContainer}
    duration={duration}
    onClose={onClose}
    btn={button}
  />
}

interface NotificationAlgorithmConnectionFailedErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationAlgorithmConnectionFailed: React.FC<NotificationAlgorithmConnectionFailedErrorProps> = ({ projectId, duration }) => {

  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-connection-failed.message'
      defaultMessage='Whoops! We have difficulties connecting to Clemex algorithm backend.'
    />
  }, [])
  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-connection-failed.description.part-1'
        defaultMessage='We expect it would come back soon, please retry again later...'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-connection-failed.description.part-2'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])
  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationAlgorithmConnectionLostDuringTrainingProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationAlgorithmConnectionLostDuringTraining: React.FC<NotificationAlgorithmConnectionLostDuringTrainingProps> = ({ projectId, duration }) => {

  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-connection-lost-during-training.message'
      defaultMessage='Whoops! Clemex Algorithm connection lost during the training'
    />
  }, [])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-connection-lost-during-training.description.retry'
        defaultMessage='Please retry again later when available...'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-connection-lost-during-training.error.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationAlgorithmConnectionLostDuringPredictionProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationAlgorithmConnectionLostDuringPrediction: React.FC<NotificationAlgorithmConnectionLostDuringPredictionProps> = ({ projectId, duration }) => {
  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-connection-lost-during-prediction.message'
      defaultMessage='Whoops! Clemex Algorithm connection lost during predictions'
    />
  },[])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-connection-lost-during-prediction.description.retry'
        defaultMessage='It will retry when available...'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-connection-lost-during-prediction.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationTrainingUnknownErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationTrainingUnknownError: React.FC<NotificationTrainingUnknownErrorProps> = ({ projectId, duration }) => {
  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-train-unknown-error.message'
      defaultMessage='Whoops! An unexpected feature broke the training'
    />
  }, [])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-train-unknown-error.description.part-1'
        defaultMessage='Lots of magic happens during the training. We try our best to control its dark side!'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-train-unknown-error.description.part-2'
        defaultMessage='The rumour says retrying your operation works sometime. You can try to train again the algorithm.'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-train-unknown-error.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface CopyButtonProps {
  message: string
}
const CopyButton: React.FC<CopyButtonProps> = ({ message }) => {
  const [isCopied, setIsCopied] = React.useState(false)
  return <Button className={styles.errorCopyButton} onClick={async () => {
    copy(message)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }}>
    {
      isCopied
        ? <CopiedIcon />
        : <CopyIcon />
    }
  </Button>
}

interface NotificationTrainingErrorProps {
  projectId: string
  duration: number // in seconds
  message: string
  onClose?: () => void
}
export const NotificationTrainingError: React.FC<NotificationTrainingErrorProps> = ({
  projectId,
  duration,
  message,
  onClose,
}) => {
  const messageTitle = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-train-error.message'
      defaultMessage='Whoops! An unexpected feature broke the training'
    />
  }, [])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-train-error.description.part-1'
        defaultMessage='Error message:'
      />
      <br />
      <div className={styles.errorContainer}>
        <pre className={styles.errorMessage} lang='en'>
          {message}
        </pre>
        <Tooltip
          title={<FormattedMessage
            id='notification.algorithm-train-error.copy-error-message'
            defaultMessage='Copy error message'
          />}
          overlayClassName={styles.errorCopyButtonTooltip}
          placement='right'
          mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
          mouseLeaveDelay={0}
        >
          <CopyButton message={message} />
        </Tooltip>
      </div>
      <br />
      <FormattedMessage
        id='notification.algorithm-train-error.description.part-2'
        defaultMessage='Lots of magic happens during the training. We try our best to control its dark side!'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-train-error.description.part-3'
        defaultMessage='The rumour says retrying your operation works sometime. You can try to train again the algorithm.'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-train-error.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div >
  }, [message])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    onClose={onClose}
    message={messageTitle}
    description={description}
  />
}

interface NotificationCreateTrainingErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationCreateTrainingError: React.FC<NotificationCreateTrainingErrorProps> = ({ projectId, duration }) => {

  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-create-train-error.message'
      defaultMessage='Whoops! An unexpected feature broke the training'
    />
  }, [])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-create-train-error.description.part-1'
        defaultMessage='Lots of magic happens during the training. We try our best to control its dark side!'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-create-train-error.description.part-2'
        defaultMessage='The rumour says retrying your operation works sometime. You can try to train again the algorithm.'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-create-train-error.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationCancelTrainingErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationCancelTrainingError: React.FC<NotificationCancelTrainingErrorProps> = ({ projectId, duration }) => {
  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-cancel-train-error.message'
      defaultMessage='Whoops! Failed to cancel the training'
    />
  }, [])
  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-cancel-train-error.description.part-1'
        defaultMessage='Please wait a moment and try again.'
      />
    </div>
  }, [])
  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationPredictionUnknownErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationPredictionUnknownError: React.FC<NotificationPredictionUnknownErrorProps> = ({ projectId, duration }) => {
  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-predict-unknown-error.message'
      defaultMessage='Whoops! An unexpected feature broke the predictions'
    />
  }, [])
  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-predict-unknown-error.description.part-1'
        defaultMessage='Lots of magic happens during the prediction. We try our best to control its dark side!'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-predict-unknown-error.description.part-2'
        defaultMessage='The rumour says retrying your operation works sometime. Try reloading the page or try training again the algorithm.'
      />
      <br />
      <br />
      <FormattedMessage
        id='notification.algorithm-predict-unknown-error.description.contact'
        defaultMessage='If you keep having troubles, click on the contact us button.'
      />
    </div>
  }, [])
  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}

interface NotificationTrainingForbiddenErrorProps {
  projectId: string
  duration: number // in seconds
}
export const NotificationTrainingForbiddenError: React.FC<NotificationTrainingForbiddenErrorProps> = ({ projectId, duration }) => {
  const message = React.useMemo(() => {
    return <FormattedMessage
      id='notification.algorithm-training-forbidden-error.message'
      defaultMessage='You do no have access to use the training feature'
    />
  }, [])

  const description = React.useMemo(() => {
    return <div>
      <FormattedMessage
        id='notification.algorithm-training-forbidden-error.description.part-1'
        defaultMessage='You are missing a permissions to use the training feature. Contact us to enable it via the I need help button.'
      />
    </div>
  }, [])

  return <NotificationWithContactUsButton
    projectId={projectId}
    duration={duration}
    message={message}
    description={description}
  />
}
