import { useImageProjectInfo, useProjectImages, useProjectTrainingSnapshot } from "@app/api/hooks"
import { ProjectDatasetContextInput, ProjectImageResponse, TaskStatus } from "@app/api/openapi"
import { usePredictionStatus, useProjectTrainingSnapshotPredictionsStatus } from "@app/api/resource-status"
import { useProjectId, useSelectedImageId, useSetSelectedImageId } from "@app/pages/editor-page/hooks/editor-page"
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as RFAPI from '@api/api'
import { Badge, Empty, Tooltip } from "antd"
import { useVirtualizer } from "@tanstack/react-virtual"
import { FormattedMessage } from "react-intl"
import { cn } from "@shadcn-ui/lib/utils"
import { EVENTS_ID, TOOLTIP_MOUSE_ENTER_DELAY } from "@app/constants"
import { JumpingDots } from "@components/common/jumping-dots"
import { api } from '@app/api/api'
import PredictionErrorIcon from '@material-design-icons/svg/outlined/error.svg'
import { ImageThumbnail, ImageThumbnailProps } from "@components/image-thumbnail"
import { ContextualMenu, ContextualMenuProps } from "@components/contextual-menu"
import MoreIcon from '@material-design-icons/svg/filled/more_vert.svg'


interface ImageListProps {
  projectId: string
  context: ProjectDatasetContextInput
  numberOfUploads: number
}
export const ImageList: FC<ImageListProps> = ({ projectId, context, numberOfUploads }) => {
  const { data: images } = useProjectImages(projectId, context)
  const selectedImageId = useSelectedImageId()

  const [shouldDisplayImageInfo, setShouldDisplayImageInfo] = useState<boolean>(false)
  const onMouseEnterImageList = useCallback((): void => {
    setShouldDisplayImageInfo(true)
  }, [setShouldDisplayImageInfo])

  const onMouseLeaveImageList = useCallback((): void => {
    setShouldDisplayImageInfo(false)
  }, [setShouldDisplayImageInfo])

  const parentRef = useRef(null)
  const rowVirtualizer = useVirtualizer({
    count: (images?.length ?? 0),
    getScrollElement: () => parentRef.current,
    estimateSize: () => 180 + 2 * 2, // image height + 2 * border width
    enabled: true,
    gap: 8,
    horizontal: false,
    overscan: 3,
  })

  // When the selected imageId changes, scroll to the selected image
  useEffect(() => {
    const imageIndex = images?.findIndex(image => image.id === selectedImageId)
    if (imageIndex !== undefined) {
      rowVirtualizer.scrollToIndex(imageIndex, { align: 'auto', behavior: 'smooth'})
    }
  }, [images, rowVirtualizer, selectedImageId])

  return <div className="flex grow min-h-[199px] max-h-full" id='image-list'>
    <div
      ref={parentRef}
      className="overflow-auto w-full max-h-full pt-[8px] pb-[8px]"
      onMouseEnter={onMouseEnterImageList}
      onMouseLeave={onMouseLeaveImageList}
    >
      <div
        className="relative grow"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {(images?.length ?? 0) === 0 && numberOfUploads === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {
          images !== undefined && rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
            const image = images[index]
            return <div
              key={image.id}
              className="absolute top-0 left-0 w-full flex justify-center"
              style={{
                height: `${size}px`,
                transform: `translateY(${start}px)`,
              }}
            >
              <ImageListItem
                projectId={projectId}
                projectDatasetContext={context}
                imageId={image.id}
                index={index}
                numberOfImages={images.length}
                shouldDisplayImageInfo={shouldDisplayImageInfo}
              />
            </div>
          })
        }
      </div>
    </div>
  </div>
}

interface ImageListItemProp {
  projectId: string
  projectDatasetContext: ProjectDatasetContextInput
  imageId: string
  index: number
  numberOfImages: number
  shouldDisplayImageInfo: boolean
}
export const ImageListItem: FC<ImageListItemProp> = ({ imageId, projectId, index, shouldDisplayImageInfo, numberOfImages, projectDatasetContext }) => {
  const { data: imageInfo } = useImageProjectInfo(projectId, imageId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const selectedImageId = useSelectedImageId()
  const setSelectedImageId = useSetSelectedImageId()
  const { data: predictionStatus, isValidating: isValidatingPredictionStatus } = usePredictionStatus(imageId, projectTrainingSnapshot?.projectTrainingSnapshotId)
  const selectedImageElementRef = useRef<HTMLDivElement>(null)

  const predictionCacheKey = useMemo(() => {
    if (imageId === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    return `${imageId}-${projectTrainingSnapshot.projectTrainingSnapshotId}`
  }, [imageId, projectTrainingSnapshot])

  // Use cache to store the prediction thumbnail URL
  // Notably, if `isValidatingPredictionStatus` is true but the URL has been cached, we can still display the thumbnail as it has been fetched before and should be valid
  const [predictionThumbnailURLCache, setPredictionThumbnailURLCache] = useState<Record<string, string>>({})
  const predictionThumbnailURL = useMemo(() => {
    if (predictionCacheKey === undefined) {
      return undefined
    }
    if (predictionThumbnailURLCache[predictionCacheKey] !== undefined) {
      return predictionThumbnailURLCache[predictionCacheKey]
    }
    if (imageInfo === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    if (isValidatingPredictionStatus || predictionStatus !== TaskStatus.Completed) {
      return undefined
    }

    const url = RFAPI.createImagePredictionThumbnail(
      imageInfo.id,
      projectTrainingSnapshot.projectTrainingSnapshotId,
      256,
      180,
    )
    return url
  }, [imageInfo, isValidatingPredictionStatus, predictionCacheKey, predictionStatus, predictionThumbnailURLCache, projectTrainingSnapshot])

  useEffect(() => {
    if (predictionThumbnailURL === undefined || predictionCacheKey === undefined) {
      return
    }
    if (predictionThumbnailURLCache[predictionCacheKey] === undefined) {
      setPredictionThumbnailURLCache({
        ...predictionThumbnailURLCache,
        [predictionCacheKey]: predictionThumbnailURL,
      })
    }
  }, [predictionCacheKey, predictionThumbnailURL, predictionThumbnailURLCache])

  return <div
    className={cn(
      " p-2px border-clemex-gray rounded-[4px] border-[2px]",
      {
        "border-clemex-offDarkGray": selectedImageId === imageId,
      },
    )}
    onClick={() => { setSelectedImageId(imageId) }}
  >
    <div className="mx-auto w-[256px] h-[180px] max-h-[180px] overflow-hidden rounded-[2px]">
      <ImageInfoWrapper
        imageIndex={index}
        imageInfo={imageInfo}
        shouldDisplayImageInfo={shouldDisplayImageInfo}
      >
        <ImagePredictionStatusWrapper
          imageId={imageId}
          hasPrediction={predictionStatus === TaskStatus.Completed}
          isPredicting={predictionStatus === TaskStatus.Running}
          isFailed={predictionStatus === TaskStatus.Failed}
          selected={selectedImageId === imageId}
        >
          <div ref={selectedImageElementRef}>
            <EditorImageThumbnail
              imageId={imageId}
              imageSlug={imageInfo?.slug}
              imageThumbnailUrl={RFAPI.createImageThumbnailURL(imageId, 256, 180)}
              imagePredictionMaskThumbnailUrl={predictionThumbnailURL}
              width={256}
              height={180}
              step={projectDatasetContext}
              isOneImage={numberOfImages === 1}
            />
          </div>
        </ImagePredictionStatusWrapper>
      </ImageInfoWrapper>
    </div>
  </div>
}



interface ImageInfoWrapperProps {
  children: ReactNode
  shouldDisplayImageInfo: boolean
  imageIndex: number
  imageInfo: ProjectImageResponse | undefined
}
const ImageInfoWrapper: FC<ImageInfoWrapperProps> = ({ children, shouldDisplayImageInfo, imageInfo, imageIndex }) => {
  return <div
    className="relative"
  >
    {children}
    <div
      className={
        cn(
          "absolute bottom-[20px] left-[6px] p-[8px]",
          "rounded-[8px] border-[2px] border-solid border-clemex-shellGray",
          "bg-clemex-gray text-clemex-darkGray",
          "pointer-events-none",
          "opacity-0 transition-opacity ease-[cubic-bezier(0.16,1,0.3,1)]",
          {
            "opacity-100": shouldDisplayImageInfo,
          },
        )
      }
    >
      <div className="uppercase text-[0.8125rem] tracking-[0.1em] text-clemex-shellDarkGray">
        <FormattedMessage id={'left-panel.images.image-info.title'} defaultMessage={'Image #{imageIndex}'} values={{ imageIndex: imageIndex + 1 }} />
      </div>
      {
        imageInfo !== undefined &&
          <>
            <div
              className="text-ellipsis whitespace-nowrap overflow-hidden text-[0.8125rem] tracking-[0.05em] pointer-events-auto"
              title={imageInfo.name}
            >
              <FormattedMessage id={'left-panel.images.image-info.image-name'} defaultMessage={'{imageName}'} values={{ imageName: imageInfo.name }} />
            </div>
            <div className="text-[0.75rem]">
              <FormattedMessage id={'left-panel.images.image-info.image-size'} defaultMessage={'{width}x{height}px'} values={{ width: imageInfo.width, height: imageInfo.height }} />
            </div>
          </>
      }
    </div>
  </div>
}


interface ImagePredictionStatusWrapperProps {
  imageId: string
  isPredicting: boolean
  hasPrediction: boolean
  isFailed: boolean
  selected: boolean
  children: ReactNode
}
export const ImagePredictionStatusWrapper: FC<ImagePredictionStatusWrapperProps> = ({ children, imageId, isPredicting, isFailed }) => {

  const ribbonClassname = "text-clemex-offDarkGray text-[15px] ml-0 w-[24px]"
  const isPredictingRibbonText = <div className={ribbonClassname}>
    <Tooltip
      title={
        <FormattedMessage id={'image-prediction-status.tooltip.status.is-prediction'} defaultMessage={'Predicting...'} />
      }
      mouseLeaveDelay={0}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
    >
      <JumpingDots />
    </Tooltip>
  </div>

  const projectId = useProjectId()
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { mutate: mutateProjectTrainingSnapshotPredictionStatus } = useProjectTrainingSnapshotPredictionsStatus(projectTrainingSnapshot?.projectTrainingSnapshotId)

  const onClickRetryPrediction = useCallback(async () => {
    await api.retryPredictionApiAlgorithmPredictionImageIdRetryPost({ imageId })
    await mutateProjectTrainingSnapshotPredictionStatus()
  }, [imageId, mutateProjectTrainingSnapshotPredictionStatus])

  const isFailedRibbonText = <div
    className={ribbonClassname}
    onClick={onClickRetryPrediction}
  >
    <Tooltip
      title={
        <FormattedMessage id={'image-prediction-status.tooltip.status.is-failed'} defaultMessage={'Prediction failed'} />
      }
      mouseLeaveDelay={0}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
    >
      <div className="fill-clemex-offDarkGray ml-[-6px] w-[24px] h-[24px]">
        <PredictionErrorIcon />
      </div>
    </Tooltip>
  </div>

  return <Badge.Ribbon
    placement="start"
    className={cn(
      "h-[27px] w-[27px] border-r-[8px] rounded-l-0",
      { "hidden": !(isPredicting || isFailed) })
    }
    text={isPredicting ? isPredictingRibbonText : isFailedRibbonText}
    color="#e6e4e3"
  >
    {children}
  </Badge.Ribbon>
}

interface ThumbnailImageMenuProps {
  children: React.ReactElement
  context?: ProjectDatasetContextInput
  imageId: string
  imageSlug?: string
  trigger?: ('contextMenu' | 'click' | 'hover')[]
  isOneImage: boolean
}
const ThumbnailImageMenu: FC<ThumbnailImageMenuProps> = ({
  children,
  context,
  trigger,
  imageId,
  isOneImage,
  imageSlug,
}) => {
  const menuItems: ContextualMenuProps['menuItems'] = {
    [EVENTS_ID.PROJECT_DELETE_IMAGE]: { imageId },
  }
  if (context === ProjectDatasetContextInput.Training) {
    menuItems[EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION] = { imageId }
  }
  if (context === ProjectDatasetContextInput.Validation) {
    menuItems[EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE] = { imageId, imageSlug }
    if (!isOneImage) {
      menuItems[EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING] = { imageId }
    }
  }

  if (context === undefined) {
    return children
  }

  return <ContextualMenu trigger={trigger} menuItems={menuItems}>
    {children}
  </ContextualMenu>
}


type EditorImageThumbnailProps = ImageThumbnailProps & {
  imageId: string
  imageSlug?: string
  isOneImage: boolean
  isDisabled?: boolean
  step?: ProjectDatasetContextInput
}
const EditorImageThumbnail: React.FC<EditorImageThumbnailProps> = ({ imageId, imageSlug, step, ...rest }) => (
  <ThumbnailImageMenu
    context={step}
    imageId={imageId}
    imageSlug={imageSlug}
    isOneImage={rest.isOneImage}
  >
    <div className="group flex items-center justify-center relative cursor-pointer">
      <ImageThumbnail
        height={rest.height}
        width={rest.width}
        imagePredictionMaskThumbnailUrl={rest.imagePredictionMaskThumbnailUrl}
        imageThumbnailUrl={rest.imageThumbnailUrl}
      />
      {
        <div
          className={
            cn(
              "absolute top-[10px] right-[10px] z-20 h-[29px] w-[29px]",
              "border-[2px] border-solid rounded-[8px]",
              "text-center leading-[29px] cursor-pointer",
              "opacity-50 group-hover:opacity-100 transition-opacity ease-[cubic-bezier(0.61,1,0.88,1)]",
              "bg-clemex-offGray border-clemex-shellGray group-hover:bg-clemex-gray group-active:bg-clemex-gray group-focus:bg-clemex-gray",
            )
          }
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            // Send a context Menu Event to display the ThumbnailMenu
            const contextMenuEvent = new MouseEvent('contextmenu', {
              bubbles: true,
              cancelable: true,
              clientX: event.nativeEvent.clientX,
              clientY: event.nativeEvent.clientY,
            })
            event.nativeEvent.target?.dispatchEvent(contextMenuEvent)
            // Avoid automatic scroll image
            event.stopPropagation()
          } }>
          <div className="w-[24px] h-[24px] fill-clemex-offDarkGray">
            <MoreIcon />
          </div>
        </div>
      }
    </div>
  </ThumbnailImageMenu>
)
