import { Draw } from 'ol/interaction'
import Transform, { type olExtStyle, type ScaleEvent, type TranslateEvent } from 'ol-ext/interaction/Transform'
import { type ChangePatch } from './common'
import { type MapBrowserEvent, type Feature, type Collection, type Map } from 'ol'
import EventType from 'ol/events/EventType'
import { Polygon } from 'ol/geom'
import type BaseEvent from 'ol/events/Event'
import { type DrawEvent } from 'ol/interaction/Draw'
import { v4 as uuidV4 } from 'uuid'
import { FEATURE_TYPE, type RectangleFeatureType } from '../shapes/common'
import type Style from 'ol/style/Style'
import { type StyleFunction } from 'ol/style/Style'
import { never, primaryAction } from 'ol/events/condition'
import { type RectangleGeometryProperties, buildBoundingBoxInteractionStyle } from '../shapes/rectangle'
import { type Layer } from 'ol/layer'

export class DrawRectangleInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _destinationCollection: Collection<Feature<Polygon>>
  private _isInteracting = false
  private _filled = false
  private readonly _drawingFeatureType: RectangleFeatureType

  constructor (
    destinationCollection: Collection<Feature<Polygon>>,
    style: StyleFunction,
    onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void,
    drawingFeatureType: RectangleFeatureType,
    filled: boolean,
  ) {
    super({
      type: 'LineString',
      minPoints: 2,
      condition: primaryAction,
      freehandCondition: (e) => {
        if (e.originalEvent instanceof MouseEvent) {
          return e.originalEvent.button === -1 || e.originalEvent.button === 0
        }
        return true
      },
      geometryFunction: (coordinates_, geometry) => {
        const coordinates = coordinates_ as [number, number][]
        const start = coordinates[0]
        const end = coordinates[coordinates.length - 1]
        const A = start
        const B = [start[0], end[1]]
        const C = end
        const D = [end[0], start[1]]
        if (geometry === undefined) {
          geometry = new Polygon([[A, B, C, D, A]])
        } else {
          (geometry as Polygon).setCoordinates([[A, B, C, D, A]])
        }
        geometry.setProperties({
          geometryType: 'RECTANGLE',
          filled: this._filled,
          pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
        } satisfies RectangleGeometryProperties)
        return geometry
      },
      style,
    })
    this._onEndInteraction = onEndInteraction
    this._destinationCollection = destinationCollection
    this._drawingFeatureType = drawingFeatureType
    this._filled = filled

    this.addEventListener('drawstart', this._onDrawStart)
    this.addEventListener('drawabort', this._onDrawAbort)
    this.addEventListener('drawend', this._onDrawEnd)
  }

  private readonly _onDrawStart = (e: Event | BaseEvent): void => {
    const drawEndEvent = e as DrawEvent
    const id = uuidV4()
    drawEndEvent.feature.setProperties({
      [FEATURE_TYPE]: this._drawingFeatureType,
      id,
    })
    this._isInteracting = true
  }

  private readonly _onDrawAbort = (): void => {
    this._isInteracting = false
  }

  private readonly _onDrawEnd = (e: Event | BaseEvent): void => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    const feature = drawEndEvent.feature as Feature<Polygon>
    if (feature.getGeometry()?.getArea() !== 0) {
      this._destinationCollection.push(feature)
      this._onEndInteraction({
        add: [{
          id: feature.getProperties().id,
          data: feature,
        }],
      })
    }
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this._onDrawStart)
    this.removeEventListener('drawabort', this._onDrawAbort)
    this.removeEventListener('drawend', this._onDrawEnd)
    super.disposeInternal()
  }
}

interface ModifyRectangleInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  pixelTolerance?: number
}
export class ModifyRectangleInteraction extends Transform {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _pixelTolerance: number
  private readonly _layers: Layer[]

  constructor ({
    modifiableFeatures,
    layers,
    onEndInteraction,
    pixelTolerance = 8,
  }: ModifyRectangleInteractionOptions) {
    super({
      features: modifiableFeatures,
      layers,
      style: buildBoundingBoxInteractionStyle(),
      enableRotatedTransform: false,
      hitTolerance: pixelTolerance,
      keepRectangle: true,
      rotate: false,
      translate: true,
      stretch: true,
      scale: true,
      selection: false,
      addCondition: never,
      noFlip: true,
      translateBBox: true,
      translateFeature: true,
      pointRadius: 10,
      modifyCenter: never,
    })
    this._onEndInteraction = onEndInteraction
    this._pixelTolerance = pixelTolerance
    this._layers = layers

    this.addEventListener('scaleend', this._onEndEvent)
    this.addEventListener('translateend', this._onEndEvent)
  }

  private readonly _onEndEvent = (_evt: Event | BaseEvent): void => {
    const evt = _evt as ScaleEvent | TranslateEvent
    this._onEndInteraction({
      update: evt.features.getArray().map((feature) => {
        return {
          id: feature.getProperties().id,
          data: feature as Feature<Polygon>,
        }
      }),
    })
  }

  public readonly setMap = (map: Map): void => {
    // XXX: `Transform.setMap` reset the style to the default one as a side-effect
    //      so we need to re-apply the style after calling super.setMap
    // Note: `setMap` is called when the interaction is added to the map
    super.setMap(map)
    Object.entries(buildBoundingBoxInteractionStyle()).forEach(([key, value]) => {
      super.setStyle(key as olExtStyle, value as Style | Style[])
    })
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('scaleend', this._onEndEvent)
    this.removeEventListener('translateend', this._onEndEvent)
    super.disposeInternal()
  }
}
