import { LineString } from 'ol/geom'
import { Draw, Modify } from 'ol/interaction'
import { type Collection, type Feature, type MapBrowserEvent } from 'ol'
import EventType from 'ol/events/EventType'
import { type DrawEvent } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { FEATURE_TYPE, type AngleFeatureType } from '../shapes/common'
import { v4 as uuidV4 } from 'uuid'
import { type ChangePatch } from './common'
import { type ModifyEvent } from 'ol/interaction/Modify'
import { type StyleFunction } from 'ol/style/Style'
import { primaryAction } from 'ol/events/condition'
import { type Layer } from 'ol/layer'
import { type AngleGeometryProperties } from '../shapes/angle'

export class DrawAngleInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private _isInteracting = false
  private readonly _drawingFeatureType: AngleFeatureType

  private readonly _destinationCollection: Collection<Feature<LineString>>

  constructor (
    destinationCollection: Collection<Feature<LineString>>,
    style: StyleFunction,
    onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void,
    drawingFeatureType: AngleFeatureType,
  ) {
    super({
      type: 'LineString',
      minPoints: 3,
      maxPoints: 3,
      style,
      geometryFunction: (coordinates_, geom) => {
        const coordinates = coordinates_ as [number, number][]
        if (geom === undefined) {
          geom = new LineString(coordinates)
        } else {
          geom.setCoordinates(coordinates)
        }
        geom?.setProperties({
          geometryType: 'ANGLE',
          pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
        } satisfies AngleGeometryProperties)
        return geom
      },
    })
    this._onEndInteraction = onEndInteraction
    this._drawingFeatureType = drawingFeatureType
    this._destinationCollection = destinationCollection

    this.addEventListener('drawstart', this._onDrawStart)
    this.addEventListener('drawabort', this._onDrawAbort)
    this.addEventListener('drawend', this._onDrawEnd)
  }

  private readonly _onDrawStart = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    const id = uuidV4()
    drawEndEvent.feature.setProperties({
      [FEATURE_TYPE]: this._drawingFeatureType,
      id,
    })
    this._isInteracting = true
  }

  private readonly _onDrawAbort = () => {
    this._isInteracting = false
  }
  private readonly _onDrawEnd = (e: Event | BaseEvent) => {
    const drawEndEvent = e as DrawEvent
    this._isInteracting = false
    const feature = drawEndEvent.feature as Feature<LineString>
    this._destinationCollection.push(feature)
    this._onEndInteraction({
      add: [
        {
          id: feature.getProperties().id,
          data: feature,
        },
      ],
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('drawstart', this._onDrawStart)
    this.removeEventListener('drawabort', this._onDrawAbort)
    this.removeEventListener('drawend', this._onDrawEnd)
    super.disposeInternal()
  }
}

interface ModifyAngleInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  pixelTolerance?: number
}
export class ModifyAngleInteraction extends Modify {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  public readonly _layers: Layer[]
  private readonly _pixelTolerance: number

  constructor ({
    modifiableFeatures,
    layers,
    onStartInteraction,
    onEndInteraction,
    pixelTolerance = 16,
  }: ModifyAngleInteractionOptions) {
    super({
      features: modifiableFeatures,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      pixelTolerance,
      insertVertexCondition: () => {
        return false
      },
    })
    this._onEndInteraction = onEndInteraction
    this._onStartInteraction = onStartInteraction
    this._layers = layers
    this._pixelTolerance = pixelTolerance

    this.addEventListener('modifystart', this._onModifyStart)
    this.addEventListener('modifyend', this._onModifyEnd)
  }

  private readonly _onModifyStart = (_modifyStartEvent: Event | BaseEvent) => {
    const modifyStartEvent = _modifyStartEvent as ModifyEvent
    this._onStartInteraction(modifyStartEvent.features.getArray() as Feature<LineString>[])
  }
  private readonly _onModifyEnd = (_modifyEndEvent: Event | BaseEvent) => {
    const modifyEndEvent = _modifyEndEvent as ModifyEvent
    const updatedFeatures = modifyEndEvent.features.getArray().map((f) => {
      return {
        id: f.getProperties().id,
        data: f as Feature<LineString>,
      }
    })
    this._onEndInteraction({
      update: updatedFeatures,
    })
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    return evt.map.hasFeatureAtPixel(evt.pixel, {
      layerFilter: (layer) => this._layers.includes(layer),
      hitTolerance: this._pixelTolerance,
    })
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  protected readonly disposeInternal = (): void => {
    this.removeEventListener('modifystart', this._onModifyStart)
    this.removeEventListener('modifyend', this._onModifyEnd)
    super.disposeInternal()
  }
}
